import {
  CoreShareModal,
  FileUpdateContextThumbnailModal,
  UserEditProfileModal,
  UserAddConnectionsModal,
  UserDetailsModal
} from '#components';

export function useUserActions() {

  async function addConnection({user}) {
    //todo do we still need this verification step?
    /*if (!store.getters.isUserEmailVerified) {
      return showUnverifiedEmailModal();
    }*/

    try {
      await useUsersStore().addConnection({user});
    } catch (e) {
      useErrorToast().add();
    }
  }

  async function addConnections() {
    return new Promise(resolve => {
      useFModal({
        id: 'add-connections',
        component: UserAddConnectionsModal,
        eventHandlers: {
          async complete() {
            resolve();
          }
        }
      }).open();
    });
  }

  async function removeConnection({user}) {
    return new Promise(resolve => {
      useConfirmModal({
        heading: `Remove Connection?`,
        message: `Are you sure you want to remove <strong>${user.name}</strong> from your Connections? They'll lose access to all content you've shared.`,
        confirmMethod: async () => {
          try {
            await useUsersStore().removeConnection({user});
            useSuccessToast().add({
              title: 'Connection Removed',
              description: `Your connection to <strong>${user.name}</strong> has been removed.`
            });
          } catch (e) {
            useErrorToast().add();
          }
        },
        onConfirm: () => resolve()
      }).open()
    });
  }

  async function shareProfile({user}) {
    useFModal({
      id: 'share-files',
      component: CoreShareModal,
      props: {
        shareObject: user,
        shareType: 'profile'
      }
    }).open();
  }

  async function editProfile({user}) {
    useFModal({
      id: 'edit-user',
      clickToClose: false,
      component: UserEditProfileModal,
      props: {user}
    }).open();
  }

  function editThumbnail({user}) {
    const modal = useFModal({
      id: 'edit-profile-photo',
      component: FileUpdateContextThumbnailModal,
      size: 'fullscreen',
      showClose: false,
      props: {
        name: user.name
      },
      eventHandlers: {
        async complete(file) {
          try {
            await useFileActions().setFileAsProfilePhoto({file});
            modal.close();
          } catch (e) {
            useErrorToast().add();
          }
        }
      }
    });

    modal.open();
  }

  function openDetailsModal({user}) {
    useFModal({
      id: 'user-details',
      component: UserDetailsModal,
      size: 'lg',
      props: {user}
    }).open();
  }

  return {
    addConnection,
    addConnections,
    removeConnection,
    shareProfile,
    editProfile,
    editThumbnail,
    openDetailsModal
  };
}
