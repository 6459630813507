<template>
  <u-dropdown :items="settingsMenu" :ui="{width: 'w-56'}">
    <template #manager>
      <div>
        <div class="flex gap-2 mb-1">
          <label class="flex-1 font-semibold">Account Manager?</label>
          <u-toggle v-model="isManagedByValue" @update:modelValue="toggleManager" />
        </div>
        <p class="text-xs">Permit {{user.name}} to upload, modify, arrange, and create projects within your account.</p>
      </div>
    </template>
    <slot />
  </u-dropdown>
</template>

<script setup>
  const props = defineProps({
    user: {
      type: Object,
      required: true
    }
  });

  const {removeConnection} = useUserActions();
  const {manageUserGroups} = useGroupsActions();
  const {toggleImpersonate, isImpersonating} = useSessionStore();

  const isManagedByValue = ref(props.user.friendship?.managed_by);
  const settingsMenu = computed(() => {
    return [
      [
        {
          label: `Group Membership (${props.user.permission_groups_count || 0})`,
          click: () => manageUserGroups({user: props.user}),
          icon: COMMON_ICONS.group
        },
        {
          label: 'Remove Connection',
          click: async () => removeConnection({user: props.user}),
          icon: COMMON_ICONS.delete
        },
        !isImpersonating && props.user.friendship.manager_of ? {
          label: 'Manage This Account',
          click: () => navigateTo(`/impersonate/${props.user.id}`),
          icon: 'i-ri-user-shared-line'
        } : null
      ].filter(n => n),
      [
        {
          click: () => {},
          slot: 'manager',
          class: 'bg-shell-200 text-charcoal cursor-default text-left'
        }
      ]
    ];
  });

  async function toggleManager(newVal) {
    try {
      isManagedByValue.value = newVal;
      await useUsersStore().toggleManager({
        user: props.user,
        isManager: newVal
      });
    } catch (e) {
      isManagedByValue.value = !newVal;
      useErrorToast().add();
    }
  }
</script>
